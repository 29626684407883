import React from 'react'
import Bredcm from '../Menhome/breadcrumb'
import Radiationoncology from '../ServisesecMen/Radiationoncology'
function Main() {
  return (
    <>
        <Bredcm subtitle="Services Details" title="Home" subtitledown="Services Details" />
        <Radiationoncology/>
    </>
  )
}

export default Main