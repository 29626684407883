import React from 'react'
import hairmakeup2 from '../../assets/img/bg/hair-makeup2.jpg'
import hairmakeup3 from '../../assets/img/bg/hair-makeup3.jpg'
import { Link } from 'react-router-dom'
import Book from "../Menhome/Booking";
import Features from '../Menhome/Features'
import Shop from '../Pages/Shop'


function Othertreatments() {
  return (
    <>
    <br/>
      <div className="about-area5 about-p p-relative">
        <div className="container pt-120 pb-90">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-4 order-1">
              <aside className="sidebar services-sidebar">
                <div className="sidebar-widget categories">
                  <div className="widget-content">
                    <ul className="services-categories">
                    <li>
                        <Link to="/medicaloncology">Medical Oncology</Link>
                      </li>
                      <li>
                        <Link to="/radiationoncology">Radiation Oncology</Link>
                      </li>
                      <li>
                        <Link to="/oncosurgery">Oncosurgery</Link>
                      </li>
                      <li>
                        <Link to="/palliativecare">Palliative Care</Link>
                      </li>
                      <li>
                        <Link to="/genetictesting">Genetic Testing</Link>
                      </li>
                      <li      className="active">
                        <Link to="/othertreatments">Other Treatments</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                
                <div className="service-detail-contact wow fadeup-animation" data-wow-delay="1.1s">
                  <h3 className="h3-title">If You Need Any Help Contact With Us</h3>
                  <a href="tel:9311198889">
                    +91-9311198889
                  </a>
                </div>
              </aside>
            </div>
           
            <div className="col-lg-8 col-md-12 col-sm-12 order-2">
              <div className="service-detail">
                <div className="content-box">
                  <h2> Other Essential Treatments </h2>
                  <Shop/>
                  <p>
                    Complete account of the systems and expound the actually teachings
                    of the great explorer of the truth, the master-builder of human
                    uts happiness. No one rejects, dislikes, or avoids pleasure
                    itself, because it is pleasure, but because those who do not know
                    how to pursue pleasure rationally.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Features/>
          <Book/>
        </div>
      </div>
    </>
  )
}

export default Othertreatments