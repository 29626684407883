import React, { useState } from 'react';
import Faqimg from '../../assets/img/bg/faq-img.png';
// import Faqimg from '../../assets/img/bg/faq-img.png';
// import Faqimg from '../../assets/img/bg/faq-img.png';
function Faqsection() {
    const [faqIndex, setFaqIndex] = useState(null);

    const faqs = [
        {
            Que: '1. Curative Surgery', 
            Answer:
                'Primary Tumor Removal: The surgical removal of the entire tumor with a margin of healthy tissue to ensure all cancerous cells are removed.',
        },
        {
            Que: '2. Debulking Surgery',
            Answer:
                'Partial removal of a tumor when it is not possible to remove it completely. This is often followed by other treatments like chemotherapy or radiation therapy to treat the remaining cancer cells. They collaborate with medical physicists, radiation therapists, dosimetrists, and other healthcare professionals to plan and deliver precise radiation treatments.'
        },
        {
            Que: '3. Palliative Surgery',
            Answer:
                'Surgery aimed at alleviating symptoms and improving quality of life rather than curing the cancer. Examples include relieving obstructions, pain, or bleeding.'
        },
        {
            Que: '4. Reconstructive Surgery',
            Answer:
                'Restoring the appearance or function of an area after significant tissue removal, such as breast reconstruction after mastectomy or facial reconstruction.',
        },
        {
            Que: '5. Preventive (Prophylactic) Surgery',
            Answer:
                ' Risk Reduction Surgery: Removal of tissue or organs in individuals at high risk of developing certain cancers, such as prophylactic mastectomy in women with BRCA mutations.',
        },
        {
            Que: '6. Biopsy',
            Answer:
                'The removal of a small tissue sample for examination to confirm a diagnosis of cancer. This can be done via needle biopsy, incisional biopsy, or excisional biopsy.',
        },
        {
            Que: '7. Staging Surgery',
            Answer:
                'Surgery to determine the extent of cancer spread (staging), which can guide treatment planning.',
        },
        {
            Que: '8. Minimally Invasive Surgery',
            Answer:
                'Uses small incisions and special instruments, including a camera, to perform surgery with less tissue damage and quicker recovery.',
        },
        {
            Que: '9. Cytoreductive Surgery with Hyperthermic Intraperitoneal Chemotherapy (HIPEC)',
            Answer:
                'Combined Treatment: Removal of visible tumors within the abdominal cavity followed by the direct application of heated chemotherapy to kill any remaining cancer cells.',
        },
    ];

    return (
        <>
            <section className="faq-area fix" style={{ backgroundColor: '#ffffff' }}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="section-title mb-50">
                                {/* <h5>FAQ</h5>
                                <h2>Frequently Asked Question</h2> */}
                            </div>
                            <div className="faq-wrap">
                                <div className="accordion" id="accordionExample">
                                    {faqs.map((faq, index) => (
                                        <div className="card" key={index}>
                                            <div className="card-header" id={`heading-${index}`}>
                                                <h2 className="mb-0">
                                                    <button
                                                        className={`faq-btn ${faqIndex === index ? '' : 'collapsed'
                                                            }`}
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        onClick={() => setFaqIndex(index)}
                                                        data-bs-target={`#collapse-${index}`}
                                                    >
                                                        {faq.Que}
                                                    </button>
                                                </h2>
                                            </div>
                                            {faqIndex === index && (
                                                <div
                                                    id={`collapse-${index}`}
                                                    className="collapse show"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <br/>
                                                    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Description</b></div>
                                                    <div className="card-body">{faq.Answer}</div>
                                                    {/* <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Purpose</b></div>
                                                    <div className="card-body">{faq.Purpose}</div> */}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-6">
                            <div className="faq-img text-right">
                                <img src={Faqimg} alt="img" className="img" />
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    );
}
export default Faqsection;
