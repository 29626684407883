import React, { useState } from 'react';
import Faqimg from '../../assets/img/bg/faq-img.png';
// import Faqimg from '../../assets/img/bg/faq-img.png';
// import Faqimg from '../../assets/img/bg/faq-img.png';
function Faqsection() {
    const [faqIndex, setFaqIndex] = useState(null);

    const faqs = [
        {
            Que: '1. Predictive Testing', 
            Answer:
                ' Determines an individual risk of developing a genetic disorder later in life, especially if there is a known familial predisposition. This type of testing can influence lifestyle choices, surveillance strategies, and early intervention plans.',
        },
        {
            Que: '2. Carrier Testing',
            Answer:
                'Identifies individuals who carry one copy of a gene mutation that, when present in two copies (one from each parent), causes a genetic disorder in their offspring. It is often performed in couples planning to have children to assess the risk of passing on genetic conditions like cystic fibrosis or sickle cell disease.'
        },
        {
            Que: '3. Prenatal Testing',
            Answer:
                'Conducted during pregnancy to detect genetic abnormalities in the fetus. This includes techniques like amniocentesis or chorionic villus sampling (CVS), which analyze fetal DNA from amniotic fluid or placental tissue, respectively.'
        },
        {
            Que: '4. Newborn Screening',
            Answer:
                'Aims to identify genetic disorders early in newborns, typically through blood tests. This allows for early intervention and treatment to prevent or minimize the impact of certain conditions.',
        },
        {
            Que: '5. Pharmacogenomic Testing',
            Answer:
                'Analyzes genetic variations that influence how individuals respond to medications. This helps healthcare providers tailor drug selection and dosages based on a persons genetic profile, improving treatment efficacy and reducing adverse effects.',
        },
    ];

    return (
        <>
            <section className="faq-area fix" style={{ backgroundColor: '#ffffff' }}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="section-title mb-50">
                                {/* <h5>FAQ</h5>
                                <h2>Frequently Asked Question</h2> */}
                            </div>
                            <div className="faq-wrap">
                                <div className="accordion" id="accordionExample">
                                    {faqs.map((faq, index) => (
                                        <div className="card" key={index}>
                                            <div className="card-header" id={`heading-${index}`}>
                                                <h2 className="mb-0">
                                                    <button
                                                        className={`faq-btn ${faqIndex === index ? '' : 'collapsed'
                                                            }`}
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        onClick={() => setFaqIndex(index)}
                                                        data-bs-target={`#collapse-${index}`}
                                                    >
                                                        {faq.Que}
                                                    </button>
                                                </h2>
                                            </div>
                                            {faqIndex === index && (
                                                <div
                                                    id={`collapse-${index}`}
                                                    className="collapse show"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <br/>
                                                    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Description</b></div>
                                                    <div className="card-body">{faq.Answer}</div>
                                                    {/* <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Purpose</b></div>
                                                    <div className="card-body">{faq.Purpose}</div> */}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-6">
                            <div className="faq-img text-right">
                                <img src={Faqimg} alt="img" className="img" />
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    );
}
export default Faqsection;
