import React, { useState } from 'react';
import Faqimg from '../../assets/img/bg/faq-img.png';
// import Faqimg from '../../assets/img/bg/faq-img.png';
// import Faqimg from '../../assets/img/bg/faq-img.png';
function Faqsection() {
    const [faqIndex, setFaqIndex] = useState(null);

    const faqs = [
        {
            Que: '1. Symptom Management', 
            Answer:
                ' Focuses on alleviating physical symptoms such as pain, nausea, fatigue, and shortness of breath.',
        },
        {
            Que: '2. Emotional and Psychological Support',
            Answer:
                'Provides counseling and mental health support to help patients and their families cope with the emotional aspects of illness..'
        },
        {
            Que: '3. Social Support',
            Answer:
                'Assists with social issues, including family dynamics, financial concerns, and accessing community resources.'
        },
        {
            Que: '4. Spiritual Care',
            Answer:
                'Offers spiritual and existential support, respecting the patient beliefs and values.',
        },
    ];

    return (
        <>
            <section className="faq-area fix" style={{ backgroundColor: '#ffffff' }}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="section-title mb-50">
                                {/* <h5>FAQ</h5>
                                <h2>Frequently Asked Question</h2> */}
                            </div>
                            <div className="faq-wrap">
                                <div className="accordion" id="accordionExample">
                                    {faqs.map((faq, index) => (
                                        <div className="card" key={index}>
                                            <div className="card-header" id={`heading-${index}`}>
                                                <h2 className="mb-0">
                                                    <button
                                                        className={`faq-btn ${faqIndex === index ? '' : 'collapsed'
                                                            }`}
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        onClick={() => setFaqIndex(index)}
                                                        data-bs-target={`#collapse-${index}`}
                                                    >
                                                        {faq.Que}
                                                    </button>
                                                </h2>
                                            </div>
                                            {faqIndex === index && (
                                                <div
                                                    id={`collapse-${index}`}
                                                    className="collapse show"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <br/>
                                                    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Description</b></div>
                                                    <div className="card-body">{faq.Answer}</div>
                                                    {/* <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Purpose</b></div>
                                                    <div className="card-body">{faq.Purpose}</div> */}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-6">
                            <div className="faq-img text-right">
                                <img src={Faqimg} alt="img" className="img" />
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    );
}
export default Faqsection;
