import React from 'react'
import Bredcm from '../Menhome/breadcrumb'
import Palliativecare from '../ServisesecMen/Palliativecare'
function Main() {
  return (
    <>
        <Bredcm subtitle="Services Details" title="Home" subtitledown="Services Details" />
        <Palliativecare/>
    </>
  )
}

export default Main