import React from "react";
import TestimonialIconTwo from "../../assets/img/testimonial/qt-icon.png";
import Iconslider from "../../assets/img/icon/pr-icon1.png";
import IconSlidertwo from "../../assets/img/icon/pr-icon2.png";
import IconSliderthree from "../../assets/img/icon/pr-icon3.png";
import Slider from "react-slick";
import { Link } from "react-router-dom";

function Testimonialslider() {
  
  const data = [
    {
      id: 1,
      img: "assets/img/testimonial/testi_avatar.png",
      img2: "assets/img/testimonial/review-icon.png",
      name: "Dr. Ketayun Ardeshir Dinshaw",
      degree: "MBBS DMRT (FRCR)",
      para: "Dr. Dinshaw was a trailblazer in Indian medicine, leaving an indelible mark on the evolution of modern cancer care and the advancement of effective  radiation therapy over three decades. In recognition of her significant contributions, she was honored with the Padma Shri Award by the President of India in 2001. Dr. Dinshaw held the distinguished honor of being the first lady to serve as Director of Tata Memorial Hospital, Mumbai, She served as Director of this institute until 2008, and played a pivotal role in shaping the institute to its present-day status as one of India's leading cancer centres.",
    },
    {
      id: 2,
      img: "assets/img/testimonial/testi_avatar_02.png",
      img2: "assets/img/testimonial/review-icon.png",
      name: "Dr. Ritu Bhutani ",
      degree: "MBBS MD (Radiation Oncology)",
      para: "Dr. Ritu Bhutani, a distinguished oncologist and visionary founder of Glean Cancer Centre, began her career under the esteemed Dr. Dinshaw in 2007. With an MBBS and MD in Radiation Oncology, she excels in comprehensive cancer diagnosis and treatment, utilizing advanced imaging and biopsy techniques. Renowned for her patient-centered approach, Dr. Bhutani crafts personalized treatment plans that integrate chemotherapy and targeted therapies. Her dedication to patient care, coupled with active involvement in clinical trials and research, ensures cutting-edge treatments. Dr. Bhutani's expertise and compassionate care make her an invaluable asset to our clinic.",

    },
  ];

  let satting = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <section className="testimonial-area pb-115 p-relative fix" style={{ backgroundColor: "#f6f8fc" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section-title text-center mb-50 wow fadeInDown animated"
                data-animation="fadeInDown"
                data-delay=".4s"
              >
                <h5>Our Roots</h5>
                <h2>Source of our credibility</h2>
              </div>
            </div>
            <div className="col-lg-12">
              <Slider className="testimonial-active" {...satting}>
                {data.map((item) => (
                  <div className="single-testimonial" key={item.id}>
                    <div className="testi-author">
                      <img src={item.img} alt="img" />
                    </div>
                    <div className="review mb-30">
                    {/* <img src={item.img2} alt="img" /> */}
                    <br/>
                      <p>{item.para}</p>
                    </div>
                    <div className="ta-info">
                      <h6>{item.name}</h6>
                      <span>{item.degree}</span>
                    </div>
                    <div className="qt-img">
                      <img src={TestimonialIconTwo} alt="img" />
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Testimonialslider;
