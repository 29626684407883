import React from 'react'
import Blogdetails01 from '../../assets/img/blog/biggerblog2.png'
// import Blogdetails02 from '../../assets/img/blog/small.png'

function seventh() {
  

  return (
    <>
        <div className="col-lg-10">
            <div className="blog-details-wrap">
                <div className="details__content pb-30">
                <h2>The Role of Caregivers: How to Support a Loved One Battling Cancer</h2>
                <div className="meta-info">
                    <ul>
                    <li>
                        <i className="fal fa-eye" /> 130 Views
                    </li>
                    <li>
                        <i className="fal fa-comments" /> 39 Comments
                    </li>
                    <li>
                        <i className="fal fa-calendar-alt" /> 23th Oct 2024
                    </li>
                    </ul>
                </div>
                <p>
                When a loved one is diagnosed with cancer, caregivers become an essential part of their journey. At Glean Cancer Centre, we understand how overwhelming caregiving can be, but remember, you are not alone. Your support, love, and dedication can make all the difference.</p>

                {/* <h3>
                What is Chemotherapy?
                </h3>
                <p>
                Chemotherapy (chemo) involves the use of drugs to kill or slow the growth of cancer cells. It is a cornerstone of cancer treatment that can be administered orally or intravenously to target cancer cells throughout the body. At Glean Hospital, our experts ensure that chemotherapy is tailored to minimize side effects while maximizing its effectiveness against cancerous cells.
                </p> */}

                {/* <blockquote>
                    <footer>The TNM Staging System Explained :</footer>
                    <h3>
                    The TNM system classifies cancer based on three factors:
                    </h3>
                </blockquote> */}
                
                    <h5>1. Be Present and Listen </h5>
                   
                     <p>
                         Sometimes, just being there is the best support. Cancer can feel isolating, and your presence brings comfort. Let them express their feelings freely, and simply listen without judgement.
                      </p>
                    <h5>2. Learn About Their Treatment  </h5>
                    

                       <p>Understanding the treatment process can help you provide better care. At Glean Cancer Centre, a leading hospital of cancer, we offer resources to help caregivers stay informed about treatments like chemotherapy and palliative care. Knowing what to expect reduces fear and empowers you to assist more effectively.</p>

                      <h5>3. Encourage What They Can Control</h5>

                     <p>
                     Help your loved one focus on what they can control, like nutrition, exercise, and emotional well-being. Establish routines, prepare meals, or accompany them to appointments at a multispeciality hospital near me like Glean Cancer Centre.
                      </p>
                   
                
                <div className="details__content-img">
                    <img src={Blogdetails01} alt="" /> 
                </div>

                <h6>4. Take Care of Yourself  </h6>      
                <p>Caregiving is demanding, and it's essential to prioritise your well-being. Take breaks, eat well, and find emotional support. Glean offers resources for caregivers, too, don’t hesitate to ask for help.
                </p>
                
                </div>

               <div>
                  <h5>5. Seek Assistance When Needed  </h5>
                  <p>Don’t try to do it all alone. Consider services like palliative care to provide relief and improve your loved one's quality of life.</p>
               </div>

               <div>
                  <h5>6. Provide Emotional Support  </h5>
                  <p>Your emotional presence is invaluable. Stay positive, celebrate small victories, and create moments of connection, like watch a movie, share a meal, or simply sit together. These moments strengthen your loved one's emotional well-being</p>
               </div>

               <div>
                  <h5>Your Support Matters </h5>
                  <p>At Glean Cancer Centre, we know caregivers are critical to the healing process. Your strength and compassion help your loved one navigate their journey, and we are here to support both of you every step of the way.
                  </p>
               </div>
              

            </div>
        </div>

    </>
  )
}

export default seventh;