import React from 'react'
import hairmakeup2 from '../../assets/img/bg/moc1.png'
import hairmakeup3 from '../../assets/img/bg/moc2.png'
import { Link } from 'react-router-dom'
import Book from "../Menhome/Booking";
import Features from '../Menhome/Features'
import Teeth from '../ServiceMen/Teeth'
import Faq from '../Menhome/medicaloncologytypes'

function Medicaloncology() {
  return (
    <>
    <br/>
      <div className="about-area5 about-p p-relative">
        <div className="container pt-120 pb-90">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-4 order-1">
              <aside className="sidebar services-sidebar">
                <div className="sidebar-widget categories">
                  <div className="widget-content">
                    <ul className="services-categories">
                    <li   className="active">
                        <Link to="/medicaloncology">Medical Oncology</Link>
                      </li>
                      <li>
                        <Link to="/radiationoncology">Radiation Oncology</Link>
                      </li>
                      <li>
                        <Link to="/oncosurgery">Oncosurgery</Link>
                      </li>
                      <li>
                        <Link to="/palliativecare">Palliative Care</Link>
                      </li>
                      <li>
                        <Link to="/genetictesting">Genetic Testing</Link>
                      </li>
                      <li>
                        <Link to="/othertreatments">Other Treatments</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                
                <div className="service-detail-contact wow fadeup-animation" data-wow-delay="1.1s">
                  <h3 className="h3-title">If You Need Any Help Contact With Us</h3>
                  <a href="tel:9311198889">
                    +91-9311198889
                  </a>
                </div>
              </aside>
            </div>
           
            <div className="col-lg-8 col-md-12 col-sm-12 order-2">
              <div className="service-detail">
                <div className="content-box">
                  <h2> Medical Oncology </h2>
                  <p>
                  Medical oncology is a branch of medicine that focuses on the diagnosis, treatment, and management of cancer. Oncologists use a variety of therapies, including chemotherapy, hormone therapy, targeted therapy, and immunotherapy, to treat cancer. The primary goal is to eradicate cancer cells, reduce tumor size, and prevent the spread of the disease, while also providing supportive care to improve the patient's quality of life.
                  </p>

                  <div className="two-column">
                    <div className="row">
                      <div className="image-column col-xl-6 col-lg-12 col-md-12">
                        <figure className="image">
                          <img src={hairmakeup2} alt="" />
                        </figure>
                      </div>
                      <div className="text-column col-xl-6 col-lg-12 col-md-12">
                        <figure className="image">
                          <img src={hairmakeup3} alt="" />
                        </figure>
                      </div>
                    </div>
                  </div>
                  <h3>Types of Medical Oncology Treatments</h3>
                  {/* <p> */}
                  <Faq/>
                 {/* <ol>
                    <li>
                      <h4>1. Chemotherapy</h4>
                      <h6>Description:</h6>
                      <p>Uses drugs to destroy cancer cells. These drugs can be administered orally or intravenously.</p>  
                      <h6>Purpose:</h6>
                      <p>To kill rapidly dividing cancer cells, shrink tumors, and prevent metastasis.</p>  
                    </li>
                    <li>
                      <h4>2. Hormone Therapy</h4>
                      <h6>Description:</h6>
                      <p>Involves the use of hormones or hormone-blocking drugs to treat certain cancers, particularly breast and prostate cancers.</p> 
                      <h6>Purpose:</h6>
                      <p>To slow or stop the growth of hormone-sensitive tumors.</p>  
                    </li>
                    <li>
                      <h4>3. Targeted Therapy</h4>
                      <h6>Description:</h6>
                      <p> Utilizes drugs or other substances that specifically target cancer cells' unique genes, proteins, or tissue environment.</p> 
                      <h6>Purpose:</h6>
                      <p>To attack cancer cells while minimizing damage to normal cells.</p>  
                    </li>
                    <li>
                      <h4>4. Immunotherapy</h4>
                      <h6>Description:</h6>
                      <p>Empowers the body's immune system to recognize and combat cancer cells.</p> 
                      <h6>Purpose:</h6>
                      <p>To enhance the natural defenses of the immune system against cancer.</p>  
                    </li>
                    <li>
                      <h4>5. Biological Therapy</h4>
                      <h6>Description:</h6>
                      <p> Involves the use of living organisms, substances derived from living organisms, or synthetic versions to treat cancer.</p> 
                      <h6>Purpose:</h6>
                      <p>To boost or restore the body's natural defense mechanisms.</p>   
                    </li>
                 </ol> */}
                <br/>
                 <p>       
                  Medical oncology is a vital field dedicated to the comprehensive care of cancer patients. By employing a range of advanced therapies and supportive care, oncologists strive to provide the best possible outcomes for patients, improving both survival rates and quality of life. For personalized treatment plans and consultations, patients are encouraged to speak with their oncologist.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Features/>
          <Book/>
          
        </div>
      </div>
    </>
  )
}

export default Medicaloncology