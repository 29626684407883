import React from 'react'
import Bredcm from '../Menhome/breadcrumb'
import About from '../Menabout/About'
import Appointment from '../Menhome/Appointment'
// import Ourdr from '../Menhome/Gallery1'
import Faq from '../Menhome/Faqsection'
// import Blog from '../Menhome/Blog'
import Gallery2 from '../Menhome/Gallery2'


function Main() {
  return (
    <>
      <Bredcm subtitle="About Us" title="Home" subtitledown="About Us"/>
      <About/>
      <Appointment/>
      <Gallery2/>
      <Faq/>
    </>
  )
}

export default Main