import React from 'react'
// import { useState } from 'react';
import Blogdetails01 from '../../../src/assets/img/blog/b_details01.png'
import Blogdetails02 from '../../assets/img/blog/b_details02.png'
// import Blogdetails03 from '../../assets/img/blog/b_details03.jpg'
// import Blogdetails04 from '../../assets/img/blog/b_details04.jpg'
// import AvatarOne from '../../assets/img/blog/comment/avatar.png'
// import Iconone from '../../assets/img/icon/c_d01.png'
// import CommentOne from '../../assets/img/blog/comment/c_01.png' 
// import CommentTwo from '../../assets/img/blog/comment/c_02.png'
// import Commentthree from '../../assets/img/blog/comment/c_03.png'
// import { Link } from 'react-router-dom'
// import LazyLoad from 'react-lazyload';



function First() {
  

  return (
    <>
        <div className="col-lg-10">
            <div className="blog-details-wrap">
                <div className="details__content pb-30">
                <h2>
                Treating the Whole Person: How Cancer Centers Are Addressing Social Needs
                </h2>
                <div className="meta-info">
                    <ul>
                    <li>
                        <i className="fal fa-eye" /> 100 Views
                    </li>
                    <li>
                        <i className="fal fa-comments" /> 35 Comments
                    </li>
                    <li>
                        <i className="fal fa-calendar-alt" /> 24th March 2019
                    </li>
                    </ul>
                </div>
                <p>
                In the journey through cancer treatment, addressing the medical aspects is crucial, but equally important is supporting the social and emotional well-being of patients. At Glean~ Cancer Centre and Multi-Speciality Hospital, we understand that a holistic approach to cancer care means treating the whole person – not just the disease. Here’s how we are dedicated to addressing the social needs of our patients:

                </p>
                <p>
               <b>1.</b>  Comprehensive Support Services: Beyond medical treatment, we offer a range of support services tailored to meet the diverse needs of our patients. This includes access to counselors, social workers, nutritionists, and financial counselors who provide guidance and support throughout the treatment process.

                </p>
                <blockquote>
                    <footer>Advertising</footer>
                    <h3>
                       Do you want a website for your business? <br/>
                       Then, Sasahyog Technologies has got your back.
                    </h3>
                </blockquote>
                <p>
               <b>2.</b> Patient Navigation Programs: Our patient navigation programs ensure that each patient has a dedicated navigator to guide them through their journey. Navigators help coordinate appointments, connect patients with resources, and provide emotional support.
                   
                </p>
                <p>
               <b>3.</b> Financial Assistance:  We understand the financial strain that can accompany cancer treatment. Our team works closely with patients to explore financial assistance options, navigate insurance coverage, and connect them with resources for financial support.
      
                </p>
                <p>
               <b>4.</b> Support Groups and Counseling: Connecting with others who understand what they are going through can be incredibly comforting for cancer patients. We offer support groups and counseling sessions where patients can share experiences, gain insights, and find emotional support.
                </p>
                <div className="details__content-img">
                    <img src={Blogdetails01} alt="" /> 
                </div>
                <p>
               <b>5.</b> Integrative Therapies:  Complementary therapies such as yoga, meditation, acupuncture, and art therapy can help reduce stress, manage symptoms, and improve overall well-being. These therapies are integrated into our treatment plans to support the healing process.
                </p>
                <p>
               <b>6.</b> Education and Outreach: We believe in empowering patients and their families with knowledge. We provide educational resources, workshops, and seminars on cancer prevention, treatment options, and coping strategies.                  
                </p>
                <p>
               <b>7.</b> Community Partnerships: Collaborating with community organizations allows us to extend our reach and provide additional support services, such as transportation assistance, housing resources, and meal programs for patients and their families.

                </p>
                
                <p>
               <b>8.</b> Survivorship Programs: Our commitment to care extends beyond treatment completion. We offer survivorship programs that focus on long-term health, wellness, and monitoring to ensure our patients continue to thrive post-treatment.
                </p>
               
                <figure>
                    <img src={Blogdetails02} alt="" />
                    <p>
              <b>Why It Matters: </b> 
                Addressing the social needs of cancer patients is not just about enhancing their quality of life during treatment; it’s about improving outcomes. Research shows that patients who receive comprehensive support are more likely to adhere to their treatment plans, experience fewer complications, and have better overall outcomes.
                </p>
                    <p>
                    At Glean Cancer Centre and Multi-Speciality Hospital , we are dedicated to treating the whole person – body, mind, and spirit. Our compassionate team is here to support each patient and their loved ones every step of the way. Together, we can navigate the challenges of cancer treatment with dignity, compassion, and hope.
                    For more information about our comprehensive cancer care services and support programs, please contact Glean Cancer Centre and Multi-Speciality Hospital. Let us be your partner in the fight against cancer, providing care that goes beyond the diagnosis to embrace the whole person.
                    </p>
                </figure>

                {/* {blogPosts.slice(1).map((post, index) => (
                   <LazyLoad key={index} height={200} offset={100} once>
                           <div className="blog-post">
                            <h2>{post.title}</h2>
                               <p>{post.content}</p>
                          </div>
                   </LazyLoad>
                    ))} */}
                
                {/* <div className="row">
                    <div className="col-xl-12 col-md-12">
                    <div className="post__tag">
                        <h5>Releted Tags</h5>
                        <ul>
                        <li>
                            <Link to="#">organic</Link>
                        </li>
                        <li>
                            <Link to="#">Foods</Link>
                        </li>
                        <li>
                            <Link to="#">tasty</Link>
                        </li>
                        </ul>
                    </div>
                    </div>
                </div> */}
                </div>
                {/* <div className="posts_navigation pt-35 pb-35">
                <div className="row align-items-center">
                    <div className="col-xl-4 col-md-5">
                    <div className="prev-link">
                        <span>Prev Post</span>
                        <h4>
                        <Link to="#">Tips Minimalist</Link>
                        </h4>
                    </div>
                    </div>
                    <div className="col-xl-4 col-md-2 text-left text-md-center">
                    <Link to="/blog" className="blog-filter">
                        <img src={Iconone} alt="" />
                    </Link>
                    </div>
                    <div className="col-xl-4 col-md-5">
                    <div className="next-link text-left text-md-right">
                        <span>next Post</span>
                        <h4>
                        <Link to="#">Less Is More</Link>
                        </h4>
                    </div>
                    </div>
                </div>
                </div> */}
                {/* <div className="related__post mt-45 mb-85"> */}
                {/* <div className="post-title">
                    <h4>Related Post</h4>
                </div> */}
                {/* <div className="row">
                    <div className="col-md-6">
                    <div className="related-post-wrap mb-30">
                        <div className="post-thumb">
                        <img src={Blogdetails03} alt="" />
                        </div>
                        <div className="rp__content">
                        <h3>
                            <Link to="#">Auis nostrud exercita ullamco laboris nisi ut</Link>
                        </h3>
                        <p>
                            Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed
                            do eiusmod temp or incididunt ut labore et dolore.
                        </p>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-6">
                    <div className="related-post-wrap mb-30">
                        <div className="post-thumb">
                        <img src={Blogdetails04} alt="" />
                        </div>
                        <div className="rp__content">
                        <h3>
                            <Link to="#">Excepteur sint occaecat cupida tat non proident</Link>
                        </h3>
                        <p>
                            Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed
                            do eiusmod temp or incididunt ut labore et dolore.
                        </p>
                        </div>
                    </div>
                    </div>
                </div> */}
                {/* </div>
                <div className="avatar__wrap text-center mb-45">
                <div className="avatar-img">
                    <img src={AvatarOne} alt="" />
                </div>
                <div className="avatar__info">
                    <h5>Rosalina William</h5>
                    <div className="avatar__info-social">
                    <Link to="#">
                        <i className="fab fa-facebook-f" />
                    </Link>
                    <Link to="#">
                        <i className="fab fa-twitter" />
                    </Link>
                    <Link to="#">
                        <i className="fab fa-instagram" />
                    </Link>
                    <Link to="#">
                        <i className="fab fa-behance" />
                    </Link>
                    <Link to="#">
                        <i className="fab fa-linkedin" />
                    </Link>
                    </div>
                </div>
                <div className="avatar__wrap-content">
                    <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequa aute irure dolor.
                    </p>
                </div>
                </div> */}
                {/* <div className="comment__wrap pb-45">
                <div className="comment__wrap-title">
                    <h5>Comments</h5>
                </div>
                <div className="single__comment mb-35">
                    <div className="comments-avatar">
                    <img src={CommentOne} alt="" />
                    </div>
                    <div className="comment-text">
                    <div className="avatar-name mb-15">
                        <h6>ALina Kelian</h6>
                        <span>19th May 2021</span>
                    </div>
                    <p>
                        The bee's knees bite your arm off bits and bobs he nicked it gosh
                        gutted mate blimey, old off his nut argy bargy vagabond buggered
                        dropped.
                    </p>
                    <Link to="#" className="comment-reply mt-15">
                        <i className="fas fa-reply" /> Reply
                    </Link>
                    </div>
                </div>
                <div className="single__comment children mb-35">
                    <div className="comments-avatar">
                    <img src={CommentTwo} alt="" />
                    </div>
                    <div className="comment-text">
                    <div className="avatar-name mb-15">
                        <h6>
                        Rlex Kelian <i className="fas fa-bookmark" />
                        </h6>
                        <span>19th May 2021</span>
                    </div>
                    <p>
                        Do one say wind up buggered bobby bite your arm off gutted mate,
                        David victoria sponge cup of char chap fanny around.
                    </p>
                    <Link to="#" className="comment-reply mt-15">
                        <i className="fas fa-reply" /> Reply
                    </Link>
                    </div>
                </div>
                <div className="single__comment">
                    <div className="comments-avatar">
                    <img src={Commentthree} alt="" />
                    </div>
                    <div className="comment-text">
                    <div className="avatar-name mb-15">
                        <h6>Roboto Alex</h6>
                        <span>21th May 2021</span>
                    </div>
                    <p>
                        Baking cakes is cobblers wellies William geeza bits and bobs what a
                        plonker it's your round,
                    </p>
                    <Link to="#" className="comment-reply mt-15">
                        <i className="fas fa-reply" /> Reply
                    </Link>
                    </div>
                </div>
                </div> */}
                {/* <div id="comments" className="comments-area  mt-45">
                <div id="respond" className="comment-respond">
                    <h3 id="reply-title" className="comment-reply-title">
                    Leave a Reply{" "}
                    <small>
                        <Link rel="nofollow" id="cancel-comment-reply-link" to="/finco/?p=2112#respond" style={{ display: "none" }} >
                        Cancel reply
                        </Link>
                    </small>
                    </h3>
                    <form action="http://wordpress.zcube.in/finco/wp-comments-post.php" method="post" id="commentform" className="comment-form" noValidate="" >
                    <p className="comment-notes">
                        <span id="email-notes">
                        Your email address will not be published.
                        </span>{" "}
                        Required fields are marked <span className="required">*</span>
                    </p>
                    <p className="comment-field">
                        <i className="fas fa-user" />
                        <input id="author" placeholder="Your Name" name="author" type="text" />
                    </p>
                    <p className="comment-field">
                        <i className="fas fa-envelope" />
                        <input id="email" placeholder="your-real-email@example.com" name="email" type="text" />
                    </p>
                    <p className="comment-field">
                        <i className="fas fa-globe" />
                        <input id="url" name="url" placeholder="http://your-site-name.com" type="text" />{" "}
                    </p>
                    <p className="comment-form-comment">
                        <label htmlFor="comment">Comment</label>{" "}
                        <textarea id="comment" name="comment" cols={45} rows={8} maxLength={65525} required="required" defaultValue={""} />
                    </p>
                    <p className="form-submit">
                        <input name="submit" type="submit" id="submit" className="submit" defaultValue="Post Comment" />{" "}
                        <input type="hidden" name="comment_post_ID" defaultValue={2112} id="comment_post_ID" />
                        <input type="hidden" name="comment_parent" id="comment_parent" defaultValue={0} />
                    </p>
                    </form>
                </div>
                </div> */}
            </div>
        </div>

    </>
  )
}

export default First