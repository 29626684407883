import React from 'react'
import hairmakeup2 from '../../assets/img/bg/roc1.png'
import hairmakeup3 from '../../assets/img/bg/roc2.png'
import { Link } from 'react-router-dom'
import Book from "../Menhome/Booking";
import Features from '../Menhome/Features'
import Faq from '../Menhome/radiationoncologytypes'

function Radiationoncology() {
  return (
    <>
    <br/>
      <div className="about-area5 about-p p-relative">
        <div className="container pt-120 pb-90">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-4 order-1">
              <aside className="sidebar services-sidebar">
                <div className="sidebar-widget categories">
                  <div className="widget-content">
                    <ul className="services-categories">
                    <li>
                        <Link to="/medicaloncology">Medical Oncology</Link>
                      </li>
                      <li      className="active">
                        <Link to="/radiationoncology">Radiation Oncology</Link>
                      </li>
                      <li>
                        <Link to="/oncosurgery">Oncosurgery</Link>
                      </li>
                      <li>
                        <Link to="/palliativecare">Palliative Care</Link>
                      </li>
                      <li>
                        <Link to="/genetictesting">Genetic Testing</Link>
                      </li>
                      <li>
                        <Link to="/othertreatments">Other Treatments</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                
                <div className="service-detail-contact wow fadeup-animation" data-wow-delay="1.1s">
                  <h3 className="h3-title">If You Need Any Help Contact With Us</h3>
                  <a href="tel:9311198889">
                    +91-9311198889
                  </a>
                </div>
              </aside>
            </div>
           
            <div className="col-lg-8 col-md-12 col-sm-12 order-2">
              <div className="service-detail">
                <div className="content-box">
                  <h2> Radiation Oncology </h2>
                  <p>
                  Radiation oncology is a specialized field of medicine focused on using radiation therapy to treat cancer. Radiation oncologists are physicians who specialize in the use of ionizing radiation to target and destroy cancer cells while minimizing damage to surrounding healthy tissues. Here’s an overview of radiation oncology and its types:
                  </p>

                  <div className="two-column">
                    <div className="row">
                      <div className="image-column col-xl-6 col-lg-12 col-md-12">
                        <figure className="image">
                          <img src={hairmakeup2} alt="" />
                        </figure>
                      </div>
                      <div className="text-column col-xl-6 col-lg-12 col-md-12">
                        <figure className="image">
                          <img src={hairmakeup3} alt="" />
                        </figure>
                      </div>
                    </div>
                  </div>
                  <Faq/>
                  <br/><br/>
                  <p>
                  Overall, radiation oncology plays a crucial role in the treatment of cancer, offering a powerful tool to either cure cancer or alleviate symptoms and improve quality of life for patients. The precise application of radiation therapy continues to evolve with advancements in technology, allowing for more targeted and effective treatments while minimizing side effects.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Features/>
          <Book/>
        </div>
      </div>
    </>
  )
}

export default Radiationoncology