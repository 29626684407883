import './App.css';
import { BrowserRouter as Router } from "react-router-dom"
import Routing from './routes';
import { FloatingWhatsApp } from 'react-floating-whatsapp'
// import FloatingButton from './FloatingButton';
import './styles.module.css';


function App() {

  return (
   <>
      <Router >
          <Routing />
      </Router>
      <FloatingWhatsApp
       phoneNumber="9084691889"
       accountName="Glean Cancer Centre & Multispeciality Hospital"
       allowEsc
       allowClickAway
       notification
       notificationSound
       statusMessage ="Glean"
      chatMessage =" Say Hi🤗"
      placeholder ="Hi"
      />
      {/* <FloatingButton 
       phoneNumber="9084691889"
       accountName="Foo"
       allowEsc
       allowClickAway
       notification
       notificationSound
       /> */}
   </>
  );
}

export default App;
