import React from 'react'
import Logo from '../../assets/img/logo/f_logo.png'
import { Link } from 'react-router-dom'



function Main() {
  return (
    <>
        <footer className="footer-bg footer-p pt-90" style={{ backgroundColor: "#00173c" }}>
                <div className="footer-top pb-70">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-xl-4 col-lg-4 col-sm-6">
                            <div className="footer-widget mb-30">
                                <div className="f-widget-title">
                                <h2>
                                    <img src={Logo} alt="logo" />
                                </h2>
                                </div>
                                <div className="footer-link">
                                <p>
                                The Glean Cancer Centre integrates state-of-the-art technology with a team of highly skilled oncologists, nurses, and support staff to deliver exceptional cancer care.
                                </p>
                                <div className="f-contact">
                                    <ul>
                                    <li>
                                        <i className="icon fal fa-clock" />
                                        <span>
                                        (OPD Timings) Monday - Saturday:
                                        <br />
                                        10:30am - 6:00pm
                                        </span>
                                    </li>
                                    <li>
                                        <i className="icon fal fa-clock" />
                                        <span>
                                        (Registration Timings) Monday - Saturday:
                                        <br />
                                        10:00am - 5:00pm
                                        </span>
                                    </li>
                                    </ul>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="col-xl-2 col-lg-2 col-sm-6">
                            <div className="footer-widget mb-30">
                                <div className="f-widget-title">
                                <h2>Other Links</h2>
                                </div>
                                <div className="footer-link">
                                <ul>
                                    <li>
                                    <Link to="/"> Home</Link>
                                    </li>
                                    <li>
                                    <Link to="/about">About Us</Link>
                                    </li>
                                    <li>
                                    <Link to="/service">Services</Link>
                                    </li>
                                    
                                    <li>
                                    <Link to="/team">Our Team</Link>
                                    </li>
                                    <li>
                                    <Link to="/blog">Latest Blog</Link>
                                    </li>
                                    <li>
                                    <Link to="/contact">Contact Us</Link>
                                    </li>
                                </ul>
                                </div>
                            </div>
                            </div>
                            <div className="col-xl-2 col-lg-2 col-sm-6">
                            <div className="footer-widget mb-30">
                                <div className="f-widget-title">
                                <h2>Our Services</h2>
                                </div>
                                <div className="footer-link">
                                <ul>
                                    <li>
                                    <Link to="/medicaloncology">Medical Oncology</Link>
                                    </li>
                                    <li>
                                    <Link to="/radiationoncology">Radiation Oncology</Link>
                                    </li>
                                    <li>
                                    <Link to="/oncosurgery">Oncosurgery</Link>
                                    </li> 
                                    <li>
                                    <Link to="/palliativecare">Palliative Care</Link>
                                    </li>
                                    <li>
                                    <Link to="/genetictesting">Genetic Testing</Link>
                                    </li>                                 
                                    <li>
                                    <Link to="/othertreatments">Other Treatments</Link>
                                    </li>
                                </ul>
                                </div>
                            </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-sm-6">
                            <div className="footer-widget mb-30">
                                <div className="f-widget-title">
                                <h2>Contact Us</h2>
                                </div>
                                <div className="f-contact">
                                <ul>
                                    <li>
                                    <i className="icon fal fa-map-marker-check" />
                                    <span>
                                        03, Shakti Nagar, 
                                        <br/> Near Rohilkhand University, <br/> Pilibhit Bypass road, Bareilly (243006)
                                    </span>
                                    </li>
                                    <li>
                                    <i className="icon fal fa-phone" />
                                    <span>
                                    <a href="tel:9311198889"> +91-9311198889 </a>
                                        <br />
                                        <a href="tel:7248043333"> +91-7248043333 </a> 
                                    </span>
                                    </li>
                                    <li>
                                    <i className="icon fal fa-envelope" />
                                    <span>
                                        <Link to="mailto:gleancc@gmail.com">gleancc@gmail.com</Link>
                                        <br />
                                        <Link to="mailto:glean.cancer@gmail.com">glean.cancer@gmail.com</Link>
                                    </span>
                                    </li>
                                </ul>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="copyright-wrap">
                    <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4">
                        <div className="copy-text">
                            Developed with ❤️ by Sasahyog Technologies Pvt Ltd.
                        </div>
                        </div>
                        <div className="col-lg-4"></div>
                        <div className="col-lg-4">
                        <div className="footer-social">
                            <a href='https://m.facebook.com/glean123/'>
                                <i className="fab fa-facebook-f" />
                            </a>
                            <a href='https://www.instagram.com/glean.cancer.centre?igsh=OXlvcm9kb2pieXZv'>
                                <i className="fab fa-instagram" />
                            </a>
                            <a href='https://twitter.com/'>
                                <i className="fab fa-whatsapp" />
                            </a>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
        </footer>

    </>
  )
}

export default Main