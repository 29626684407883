import React from 'react'
import hairmakeup2 from '../../assets/img/bg/OS1.png'
import hairmakeup3 from '../../assets/img/bg/OS2.png'
import { Link } from 'react-router-dom'
import Book from "../Menhome/Booking";
import Features from '../Menhome/Features'
import Faq from '../Menhome/oncosurgerytypes'
//import Faq from '../Menhome/palliativecaretypes'
function Oncosurgery() {
  return (
    <>
    <br/>
      <div className="about-area5 about-p p-relative">
        <div className="container pt-120 pb-90">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-4 order-1">
              <aside className="sidebar services-sidebar">
                <div className="sidebar-widget categories">
                  <div className="widget-content">
                    <ul className="services-categories">
                    <li>
                        <Link to="/medicaloncology">Medical Oncology</Link>
                      </li>
                      <li>
                        <Link to="/radiationoncology">Radiation Oncology</Link>
                      </li>
                      <li     className="active">
                        <Link to="/oncosurgery">Oncosurgery</Link>
                      </li>
                      <li>
                        <Link to="/palliativecare">Palliative Care</Link>
                      </li>
                      <li>
                        <Link to="/genetictesting">Genetic Testing</Link>
                      </li>
                      <li>
                        <Link to="/othertreatments">Other Treatments</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                
                <div className="service-detail-contact wow fadeup-animation" data-wow-delay="1.1s">
                  <h3 className="h3-title">If You Need Any Help Contact With Us</h3>
                  <a href="tel:9311198889">
                    +91-9311198889
                  </a>
                </div>
              </aside>
            </div>
           
            <div className="col-lg-8 col-md-12 col-sm-12 order-2">
              <div className="service-detail">
                <div className="content-box">
                  <h2>Oncosurgery </h2>
                  <p>
                  Oncosurgery, also known as surgical oncology, is a branch of surgery focused on the surgical treatment of cancer. It involves the removal of tumors, cancerous tissues, and sometimes adjacent structures to help prevent the spread of cancer and improve patient outcomes. 

                  </p>

                  <div className="two-column">
                    <div className="row">
                      <div className="image-column col-xl-6 col-lg-12 col-md-12">
                        <figure className="image">
                          <img src={hairmakeup2} alt="" />
                        </figure>
                      </div>
                      <div className="text-column col-xl-6 col-lg-12 col-md-12">
                        <figure className="image">
                          <img src={hairmakeup3} alt="" />
                        </figure>
                      </div>
                    </div>
                  </div>
                  <Faq/>
                  <br/><br/>
                  <p>
                  Oncosurgery is often part of a multidisciplinary approach to cancer treatment, which may include chemotherapy, radiation therapy, immunotherapy, and targeted therapy, depending on the type and stage of cancer. The goal is to achieve the best possible outcome for the patient, whether that means curing the cancer, prolonging survival, or improving the quality of life.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Features/>
          <Book/>
        </div>
      </div>
    </>
  )
}

export default Oncosurgery