import React from 'react'
import hairmakeup2 from '../../assets/img/bg/GT1.png'
import hairmakeup3 from '../../assets/img/bg/GT2.png'
import { Link } from 'react-router-dom'
import Book from "../Menhome/Booking";
import Features from '../Menhome/Features'
import Faq from '../Menhome/genetictestingtypes'
function Genetictesting() {
  return (
    <>
    <br/>
      <div className="about-area5 about-p p-relative">
        <div className="container pt-120 pb-90">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-4 order-1">
              <aside className="sidebar services-sidebar">
                <div className="sidebar-widget categories">
                  <div className="widget-content">
                    <ul className="services-categories">
                    <li>
                        <Link to="/medicaloncology">Medical Oncology</Link>
                      </li>
                      <li>
                        <Link to="/radiationoncology">Radiation Oncology</Link>
                      </li>
                      <li>
                        <Link to="/oncosurgery">Oncosurgery</Link>
                      </li>
                      <li>
                        <Link to="/palliativecare">Palliative Care</Link>
                      </li>
                      <li    className="active">
                        <Link to="/genetictesting">Genetic Testing</Link>
                      </li>
                      <li>
                        <Link to="/othertreatments">Other Treatments</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                
                <div className="service-detail-contact wow fadeup-animation" data-wow-delay="1.1s">
                  <h3 className="h3-title">If You Need Any Help Contact With Us</h3>
                  <a href="tel:9311198889">
                    +91-9311198889
                  </a>
                </div>
              </aside>
            </div>
           
            <div className="col-lg-8 col-md-12 col-sm-12 order-2">
              <div className="service-detail">
                <div className="content-box">
                  <h2> Genetic Testing </h2>
                  <p>
                  Genetic testing is a medical test that examines a person's DNA, RNA, or chromosomes for changes or mutations that may be associated with a genetic disorder or disease susceptibility. It helps in identifying inherited genetic conditions, predicting the risk of developing certain diseases, and guiding personalized treatment approaches.
                  </p>

                  <div className="two-column">
                    <div className="row">
                      <div className="image-column col-xl-6 col-lg-12 col-md-12">
                        <figure className="image">
                          <img src={hairmakeup2} alt="" />
                        </figure>
                      </div>
                      <div className="text-column col-xl-6 col-lg-12 col-md-12">
                        <figure className="image">
                          <img src={hairmakeup3} alt="" />
                        </figure>
                      </div>
                    </div>
                  </div>
                  <Faq/>
                  <br/><br/>
                  {/* <h3>Why Choose This Service</h3> */}
                  <p>
                  Genetic testing is usually conducted under the guidance of healthcare professionals trained in genetics. It involves obtaining a sample (often blood or saliva), which is then analyzed in a laboratory to detect specific genetic alterations. Counseling both before and after testing is often recommended to help individuals understand the implications of their results, including medical, psychological, and familial considerations.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Features/>
          <Book/>
        </div>
      </div>
    </>
  )
}

export default Genetictesting