import React from 'react'
// import { useState } from 'react';
import Blogdetails01 from '../../../src/assets/img/blog/bl3.png'
import Blogdetails02 from '../../assets/img/blog/b_details02.png'
// import Blogdetails03 from '../../assets/img/blog/b_details03.jpg'
// import Blogdetails04 from '../../assets/img/blog/b_details04.jpg'
// import AvatarOne from '../../assets/img/blog/comment/avatar.png'
// import Iconone from '../../assets/img/icon/c_d01.png'
// import CommentOne from '../../assets/img/blog/comment/c_01.png' 
// import CommentTwo from '../../assets/img/blog/comment/c_02.png'
// import Commentthree from '../../assets/img/blog/comment/c_03.png'
// import { Link } from 'react-router-dom'
// import LazyLoad from 'react-lazyload';



function Third() {
  

  return (
    <>
        <div className="col-lg-10">
            <div className="blog-details-wrap">
                <div className="details__content pb-30">
                <h2>
                Palliative care serves as invaluable support for individuals navigating any stage of their cancer journey, as one expert detailed.
                </h2>
                <div className="meta-info">
                    <ul>
                    <li>
                        <i className="fal fa-eye" /> 100 Views
                    </li>
                    <li>
                        <i className="fal fa-comments" /> 35 Comments
                    </li>
                    <li>
                        <i className="fal fa-calendar-alt" /> 24th March 2019
                    </li>
                    </ul>
                </div>
                <p>
                Palliative care involves specialized medical attention for those living with a serious illness. Our primary focus is on alleviating symptoms and easing the burden of the illness. It's often referred to as supportive care, which perhaps better captures our role. Ultimately, our aim is to enhance the quality of life for both patients and their families.

                </p>
                <p>
               Key symptoms commonly experienced by individuals with breast cancer, which palliative care teams aim to address, include pain, fatigue, nausea, vomiting, lymphedema (swelling), cognitive issues, emotional distress, sleep disturbances, and menopausal symptoms.

                </p>
                <blockquote>
                    <footer>By Rosalina Pong</footer>
                    <h3>
                    We understand that the illness experience varies greatly for each individual, encompassing emotional, spiritual, and physical dimensions,
                    </h3>
                </blockquote>
                <p>
                Thus, our approach is collaborative, involving doctors, nurses, and specialized professionals such as chaplains and social workers. Our role is to provide additional support. Importantly, our services are appropriate at any stage of the illness and are complementary to curative treatments. It’s common for patients to mistakenly associate our care with hospice services or assume we have a specific agenda, but that’s not the case. Our focus is solely on supporting you through your treatment process.
                  
                </p>
                <p>
                   We understand that the illness experience varies greatly for each individual, encompassing emotional, spiritual, and physical dimensions, Thus, our approach is collaborative, involving doctors, nurses, and specialized professionals such as chaplains and social workers. Our role is to provide additional support. Importantly, our services are appropriate at any stage of the illness and are complementary to curative treatments. It’s common for patients to mistakenly associate our care with hospice services or assume we have a specific agenda, but that’s not the case. Our focus is solely on supporting you through your treatment process. The experts outlined what patients can anticipate during a palliative care visit, highlighting that initial appointments often involve completing the Edmonton Symptom Assessment System, which evaluates symptom severity on a scale of 0 to 10.Furthermore, palliative care isn't just about managing physical symptoms. It also encompasses emotional and psychological support, helping patients and their families navigate the complex emotions that accompany a serious illness. This holistic approach ensures that all aspects of a patient's well-being are addressed, from physical comfort to emotional resilience. In addition to medical treatment, we offer counseling and support groups to help patients and families cope with the emotional impact of cancer. We also provide guidance on advance care planning, helping patients make informed decisions about their future care preferences. This proactive approach empowers patients, giving them a sense of control over their treatment and quality of life.       
                </p>
                
                <div className="details__content-img">
                    <img src={Blogdetails01} alt="" /> 
                </div>
       
                <p>
                   By integrating palliative care early in the cancer journey, patients can benefit from improved symptom management and a better overall quality of life. Studies have shown that patients receiving palliative care often experience fewer hospitalizations and emergency room visits, allowing them to spend more time at home with their loved ones. Our ultimate goal is to ensure that our patients live as well as possible and for as long as possible. Palliative care is about enhancing life, providing comfort, and supporting both patients and their families through every step of their journey.
                </p>
               
            
               

                {/* {blogPosts.slice(1).map((post, index) => (
                   <LazyLoad key={index} height={200} offset={100} once>
                           <div className="blog-post">
                            <h2>{post.title}</h2>
                               <p>{post.content}</p>
                          </div>
                   </LazyLoad>
                    ))} */}
                
                {/* <div className="row">
                    <div className="col-xl-12 col-md-12">
                    <div className="post__tag">
                        <h5>Releted Tags</h5>
                        <ul>
                        <li>
                            <Link to="#">organic</Link>
                        </li>
                        <li>
                            <Link to="#">Foods</Link>
                        </li>
                        <li>
                            <Link to="#">tasty</Link>
                        </li>
                        </ul>
                    </div>
                    </div>
                </div> */}
                </div>
                {/* <div className="posts_navigation pt-35 pb-35">
                <div className="row align-items-center">
                    <div className="col-xl-4 col-md-5">
                    <div className="prev-link">
                        <span>Prev Post</span>
                        <h4>
                        <Link to="#">Tips Minimalist</Link>
                        </h4>
                    </div>
                    </div>
                    <div className="col-xl-4 col-md-2 text-left text-md-center">
                    <Link to="/blog" className="blog-filter">
                        <img src={Iconone} alt="" />
                    </Link>
                    </div>
                    <div className="col-xl-4 col-md-5">
                    <div className="next-link text-left text-md-right">
                        <span>next Post</span>
                        <h4>
                        <Link to="#">Less Is More</Link>
                        </h4>
                    </div>
                    </div>
                </div>
                </div> */}
                {/* <div className="related__post mt-45 mb-85"> */}
                {/* <div className="post-title">
                    <h4>Related Post</h4>
                </div> */}
                {/* <div className="row">
                    <div className="col-md-6">
                    <div className="related-post-wrap mb-30">
                        <div className="post-thumb">
                        <img src={Blogdetails03} alt="" />
                        </div>
                        <div className="rp__content">
                        <h3>
                            <Link to="#">Auis nostrud exercita ullamco laboris nisi ut</Link>
                        </h3>
                        <p>
                            Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed
                            do eiusmod temp or incididunt ut labore et dolore.
                        </p>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-6">
                    <div className="related-post-wrap mb-30">
                        <div className="post-thumb">
                        <img src={Blogdetails04} alt="" />
                        </div>
                        <div className="rp__content">
                        <h3>
                            <Link to="#">Excepteur sint occaecat cupida tat non proident</Link>
                        </h3>
                        <p>
                            Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed
                            do eiusmod temp or incididunt ut labore et dolore.
                        </p>
                        </div>
                    </div>
                    </div>
                </div> */}
                {/* </div>
                <div className="avatar__wrap text-center mb-45">
                <div className="avatar-img">
                    <img src={AvatarOne} alt="" />
                </div>
                <div className="avatar__info">
                    <h5>Rosalina William</h5>
                    <div className="avatar__info-social">
                    <Link to="#">
                        <i className="fab fa-facebook-f" />
                    </Link>
                    <Link to="#">
                        <i className="fab fa-twitter" />
                    </Link>
                    <Link to="#">
                        <i className="fab fa-instagram" />
                    </Link>
                    <Link to="#">
                        <i className="fab fa-behance" />
                    </Link>
                    <Link to="#">
                        <i className="fab fa-linkedin" />
                    </Link>
                    </div>
                </div>
                <div className="avatar__wrap-content">
                    <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequa aute irure dolor.
                    </p>
                </div>
                </div> */}
                {/* <div className="comment__wrap pb-45">
                <div className="comment__wrap-title">
                    <h5>Comments</h5>
                </div>
                <div className="single__comment mb-35">
                    <div className="comments-avatar">
                    <img src={CommentOne} alt="" />
                    </div>
                    <div className="comment-text">
                    <div className="avatar-name mb-15">
                        <h6>ALina Kelian</h6>
                        <span>19th May 2021</span>
                    </div>
                    <p>
                        The bee's knees bite your arm off bits and bobs he nicked it gosh
                        gutted mate blimey, old off his nut argy bargy vagabond buggered
                        dropped.
                    </p>
                    <Link to="#" className="comment-reply mt-15">
                        <i className="fas fa-reply" /> Reply
                    </Link>
                    </div>
                </div>
                <div className="single__comment children mb-35">
                    <div className="comments-avatar">
                    <img src={CommentTwo} alt="" />
                    </div>
                    <div className="comment-text">
                    <div className="avatar-name mb-15">
                        <h6>
                        Rlex Kelian <i className="fas fa-bookmark" />
                        </h6>
                        <span>19th May 2021</span>
                    </div>
                    <p>
                        Do one say wind up buggered bobby bite your arm off gutted mate,
                        David victoria sponge cup of char chap fanny around.
                    </p>
                    <Link to="#" className="comment-reply mt-15">
                        <i className="fas fa-reply" /> Reply
                    </Link>
                    </div>
                </div>
                <div className="single__comment">
                    <div className="comments-avatar">
                    <img src={Commentthree} alt="" />
                    </div>
                    <div className="comment-text">
                    <div className="avatar-name mb-15">
                        <h6>Roboto Alex</h6>
                        <span>21th May 2021</span>
                    </div>
                    <p>
                        Baking cakes is cobblers wellies William geeza bits and bobs what a
                        plonker it's your round,
                    </p>
                    <Link to="#" className="comment-reply mt-15">
                        <i className="fas fa-reply" /> Reply
                    </Link>
                    </div>
                </div>
                </div> */}
                {/* <div id="comments" className="comments-area  mt-45">
                <div id="respond" className="comment-respond">
                    <h3 id="reply-title" className="comment-reply-title">
                    Leave a Reply{" "}
                    <small>
                        <Link rel="nofollow" id="cancel-comment-reply-link" to="/finco/?p=2112#respond" style={{ display: "none" }} >
                        Cancel reply
                        </Link>
                    </small>
                    </h3>
                    <form action="http://wordpress.zcube.in/finco/wp-comments-post.php" method="post" id="commentform" className="comment-form" noValidate="" >
                    <p className="comment-notes">
                        <span id="email-notes">
                        Your email address will not be published.
                        </span>{" "}
                        Required fields are marked <span className="required">*</span>
                    </p>
                    <p className="comment-field">
                        <i className="fas fa-user" />
                        <input id="author" placeholder="Your Name" name="author" type="text" />
                    </p>
                    <p className="comment-field">
                        <i className="fas fa-envelope" />
                        <input id="email" placeholder="your-real-email@example.com" name="email" type="text" />
                    </p>
                    <p className="comment-field">
                        <i className="fas fa-globe" />
                        <input id="url" name="url" placeholder="http://your-site-name.com" type="text" />{" "}
                    </p>
                    <p className="comment-form-comment">
                        <label htmlFor="comment">Comment</label>{" "}
                        <textarea id="comment" name="comment" cols={45} rows={8} maxLength={65525} required="required" defaultValue={""} />
                    </p>
                    <p className="form-submit">
                        <input name="submit" type="submit" id="submit" className="submit" defaultValue="Post Comment" />{" "}
                        <input type="hidden" name="comment_post_ID" defaultValue={2112} id="comment_post_ID" />
                        <input type="hidden" name="comment_parent" id="comment_parent" defaultValue={0} />
                    </p>
                    </form>
                </div>
                </div> */}
            </div>
        </div>

    </>
  )
}

export default Third ;