import React from 'react'
import Bredcm from '../Menhome/breadcrumb'
import Genetictesting from '../ServisesecMen/Genetictesting'
function Main() {
  return (
    <>
        <Bredcm subtitle="Services Details" title="Home" subtitledown="Services Details" />
        <Genetictesting/>
    </>
  )
}

export default Main