import React from 'react'
import FeaturesservicesTwo from '../../assets/img/features/about_img2.png'
import { Link } from 'react-router-dom'



function About() {
  return (
    <>
        <section className="about-area about-p pt-120 pb-120 p-relative fix">
            <div className="container">
                    <div className="row justify-content-center align-items-center">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="s-about-img p-relative  wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s" >
                        <img src={FeaturesservicesTwo} alt="img" />
                        <div className="about-text second-about">
                            <span>1K</span>
                            <p>+ Success Stories</p>
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="about-content s-about-content  wow fadeInRight  animated"  data-animation="fadeInRight" data-delay=".4s" >
                        <div className="about-title second-title pb-25">
                            <h5>Advanced Technologies for Precision Medicine
</h5>
                            <h2>Your life is in secure hands.</h2>
                        </div>
                        <p>
                        Our commitment to precision medicine is exemplified by our use of cutting-edge technologies. Through genomic sequencing, we meticulously analyze the genetic composition of your tumor to identify specific mutations or alterations driving its growth. This invaluable information allows us to develop personalized treatment plans tailored to target the unique characteristics of your cancer. 
                        </p>
                        <p>
                        Utilizing advanced techniques such as next-generation sequencing, we unlock vital insights that guide our treatment decisions, thereby enhancing the likelihood of successful outcomes.
                        </p>
                        <div className="slider-btn mt-20">
                            <Link to="/contact" className="btn ss-btn smoth-scroll">
                            Get In Touch
                            </Link>
                        </div>
                        </div>
                    </div>
                    </div>
            </div>
        </section>
    </>
  )
}

export default About