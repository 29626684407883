import React from "react";
import Ourservish from "../Menhome/Ourservices";
import Book from "../Menhome/Booking";
import Testimo from "../Menhome/Testimonialslider";
import Service from "../Menhome/Services";
// import Faq from "../Menhome/Faqsection";
// import OurDr from "../Menhome/Gallery1";
// import Gallery2 from "../Menhome/Gallery2";
import Appointment from "../Menhome/Appointment";
import Blog from "../Menhome/Blog2";
import SliderTwo from "../HomeTwoslider/SliderTwo";

function Main() {
  return (
    <>
      <SliderTwo />
      <br/>
      <Book />
      <br/><br/><br/>
      <Ourservish />
      <Testimo />
      <Service />
      <br/><br/><br/>
      <Appointment />
      {/* <OurDr /> */}
      {/* <Gallery2/> */}
      <Blog />
    </>
  );
}

export default Main;
