import React, { useState } from 'react';
import Faqimg from '../../assets/img/bg/faq-img.png';
// import Faqimg from '../../assets/img/bg/faq-img.png';

function Faqsection() {
    const [faqIndex, setFaqIndex] = useState(null);

    const faqs = [
        {
            Que: '1. Chemotherapy', 
            Answer:
                'Uses drugs to destroy cancer cells. These drugs can be administered orally or intravenously.',
            Purpose:
                'To kill rapidly dividing cancer cells, shrink tumors, and prevent metastasis.'
        },
        {
            Que: '2. Hormone Therapy',
            Answer:
                'Involves the use of hormones or hormone-blocking drugs to treat certain cancers, particularly breast and prostate cancers.',
            Purpose:
                'To slow or stop the growth of hormone-sensitive tumors.'
        },
        {
            Que: '3. Targeted Therapy',
            Answer:
                'Utilizes drugs or other substances that specifically target cancer cells unique genes, proteins, or tissue environment.',
            Purpose:
                'To attack cancer cells while minimizing damage to normal cells.'
        },
        {
            Que: '4. Immunotherapy',
            Answer:
                'Empowers the body immune system to recognize and combat cancer cells.',
            Purpose:
                'To enhance the natural defenses of the immune system against cancer.'
        },
        {
            Que: '5. Biological Therapy',
            Answer:
                'Involves the use of living organisms, substances derived from living organisms, or synthetic versions to treat cancer.',
            Purpose:
                'To boost or restore the body natural defense mechanisms.'
        },
    ];

    return (
        <>
            <section className="faq-area fix" style={{ backgroundColor: '#ffffff' }}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="section-title mb-50">
                                {/* <h5>FAQ</h5>
                                <h2>Frequently Asked Question</h2> */}
                            </div>
                            <div className="faq-wrap">
                                <div className="accordion" id="accordionExample">
                                    {faqs.map((faq, index) => (
                                        <div className="card" key={index}>
                                            <div className="card-header" id={`heading-${index}`}>
                                                <h2 className="mb-0">
                                                    <button
                                                        className={`faq-btn ${faqIndex === index ? '' : 'collapsed'
                                                            }`}
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        onClick={() => setFaqIndex(index)}
                                                        data-bs-target={`#collapse-${index}`}
                                                    >
                                                        {faq.Que}
                                                    </button>
                                                </h2>
                                            </div>
                                            {faqIndex === index && (
                                                <div
                                                    id={`collapse-${index}`}
                                                    className="collapse show"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <br/>
                                                    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Description</b></div>
                                                    <div className="card-body">{faq.Answer}</div>
                                                    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Purpose</b></div>
                                                    <div className="card-body">{faq.Purpose}</div>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-6">
                            <div className="faq-img text-right">
                                <img src={Faqimg} alt="img" className="img" />
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    );
}
export default Faqsection;
