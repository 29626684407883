import React from "react";
import Featuresservices from "../../assets/img/features/services-img-details2.png";
// import FeaturesservicesTwo from "../../assets/img/features/about_img.png";
// import { Link } from "react-router-dom";

function Services() {
  const data = [
    {
      id: 1,
      name: "Medical Oncology",
      img: "assets/img/icon/fe-icon01.png",
      desc: "Medical oncology is a branch of medicine that focuses on the diagnosis, treatment, and management of cancer."
    },
    {
      id: 2,
      name: "Radiation Oncology",
      img: "assets/img/icon/fe-icon04.png",
      desc: "Radiation oncology is a medical specialty focused on the use of radiation therapy to treat cancer."
    },
    {
      id: 3,
      name: "Oncosurgery ",
      img: "assets/img/icon/fe-icon07.png",
      desc: "Oncosurgery, also known as surgical oncology, is a branch of surgery focused on the surgical treatment of cancer."
    },
   ];
  const dataone = [
    {
      id: 4,
      name: "Palliative Care",
      img: "assets/img/icon/fe-icon06.png",
      desc: "Palliative care improves the quality of life for patients and It focuses on providing comfort and dignity throughout the illness."
    },
    {
      id: 5,
      name: "Genetic Testing",
      img: "assets/img/icon/fe-icon05.png",
      desc: "Genetic testing for cancer involves analyzing DNA to identify inherited mutations."

    },
    {
      id: 6,
      name: "Other Treatments",
      img: "assets/img/icon/fe-icon08.png",
      desc: "From Preventive medicines for cancer to helping people by diagnosing and solving medical issues."

    },
  ];
  return (
    <>
    {/* <section className="about-area about-p pb-120 p-relative fix">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div
                className="s-about-img p-relative  wow fadeInLeft animated"
                data-animation="fadeInLeft"
                data-delay=".4s"
              >
                <img src={FeaturesservicesTwo} alt="img" />
                <div className="about-text second-about">
                  <span>20</span>
                  <p>Years of Experience</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div
                className="about-content s-about-content  wow fadeInRight  animated"
                data-animation="fadeInRight"
                data-delay=".4s"
              >
                <div className="about-title second-title pb-35">
                  <h5>Overview</h5>
                  <h2>Your health is our priority.</h2>
                </div>
                <p>
                At Glean Cancer Centre, we understand the profound impact a cancer diagnosis can have on your life. We are dedicated to providing comprehensive and personalized care to our patitents and online consultation and assistance to our international patients.                 </p>
                <p>
                The Glean Cancer Centre integrates state-of-the-art technology with a team of highly skilled oncologists, nurses, and support staff to deliver exceptional care for a wide range of cancers.
                </p>

                <div className="slider-btn mt-20">
                  <Link to="about.html" className="btn ss-btn smoth-scroll">
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
       </section>  */}
       <section id="service-details2" className="service-details-two pt-120 pb-105 p-relative">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="section-title center-align mb-50 text-center">
                <h5>Our Expertise</h5>
                <h2>Carcinoma Services</h2>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="about-content s-about-content">
                <ul className="sr-tw-ul text-right">
                  {data.map((item) => (
                    <li key={item.id}>
                      <div className="text">
                        <h4>{item.name}</h4>
                        <p>
                          {item.desc}
                        </p>
                      </div>
                      <div className="icon-right">
                        {/* <img src={item.img} alt="icon01" /> */}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 text-center d-none d-lg-block">
              <div className="sd-img">
                <img src={Featuresservices} alt="img" />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="about-content s-about-content">
                <ul className="sr-tw-ul">
                  {dataone.map((item) => (
                    <li key={item.id}>
                      <div className="icon">
                        {/* <img src={item.img} alt="icon01" /> */}
                      </div>
                      <div className="text pt-10">
                        <h4>{item.name}</h4>
                        <p>
                          {item.desc}
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section> 
    </>
  );
}

export default Services;
