import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

function Gallery2() {
  const data = [
    {
      id: 1,
      img: "assets/img/team/1.jpg",
      name: "Dr. Ritu Bhutani",
      Department: "Oncology",
      Qualification: "(MBBS, MD)"
    },
    {
      id: 2,
      img: "assets/img/team/IM.jpg",
      name: "Dr. Prateek Singh",
      Department: "Internal Medicine",
      Qualification: "(MBBS, MD)"
    },
    {
      id: 3,
      img: "assets/img/team/SO.jpg",
      name: "Dr. Shubhanshu Gupta",
      Department: "Surgical Oncology",
      Qualification: "(MS, MCH)"
    },
    {
      id: 4,
      img: "assets/img/team/GS.jpg",
      name: "Dr. Aditya Arya",
      Department: "General Surgeon",
      Qualification: "(MS)"
    },
    {
      id: 5,
      img: "assets/img/team/MO.jpg",
      name: "Dr. Amit Upadhayay",
      Department: "Medical Oncologist",
      Qualification: "(MBBS, MD)"
    },
    {
      id: 6,
      img: "assets/img/team/ANT.jpg",
      name: "Dr. Mohd Khalik",
      Department: "Anestheaia",
      Qualification: "(MBBS, MD)"
    },
    {
      id: 7,
      img: "assets/img/team/ENT.jpg",
      name: "Dr. Paras Jain",
      Department: "ENT",
      Qualification: "(MBBS, MS)"
    },
    {
      id: 8,
      img: "assets/img/team/GLS.jpg",
      name: "Dr. Harsh Vardhan",
      Department: "General & Laproscopic Surgeon",
      Qualification: "(MBBS, MS)"
    },
    {
      id: 9,
      img: "assets/img/team/URL.jpg",
      name: "Dr. Rishi Agarwal",
      Department: "Urology",
      Qualification: "(MBBS, MD, DNB)"
    },
    {
      id: 10,
      img: "assets/img/team/CH.jpg",
      name: "Dr. Achal Mehrotra",
      Department: "Chest",
      Qualification: "(MBBS, MD)"
    },
    {
      id: 11,
      img: "assets/img/team/ORTHO.jpg",
      name: "Dr. Ashish Agarwal",
      Department: "Orthopedics",
      Qualification: "(MBBS, MS)"
    },
    {
      id: 12,
      img: "assets/img/team/PTH.jpg",
      name: "Dr. Pooja",
      Department: "Pathologist",
      Qualification: ""
    },
    {
      id: 13,
      img: "assets/img/team/ANT.jpg",
      name: "Dr. Swati Kanchan",
      Department: "Anestheasia",
      Qualification: ""
    },
    {
      id: 14,
      img: "assets/img/team/ENT (2).jpg",
      name: "Dr. Abhinav",
      Department: "ENT",
      Qualification: "(MBBS, MS)"
    },
    {
      id: 15,
      img: "assets/img/team/GM.jpg",
      name: "Dr. Shakti Kansal",
      Department: "General Medicine",
      Qualification: "(MBBS, MD)"
    },
    {
      id: 16,
      img: "assets/img/team/GYN.jpg",
      name: "Dr. Chavi Bass",
      Department: "Obsteics And Gynaecology",
      Qualification: "(MBBS DGO)"
    },
  ];
  const satting = {
    dots: true,
    infinite: true,
    arrows: false,
    prevArrow:
      '<button type="button" className="slick-prev"><i className="far fa-chevron-left"></i></button>',
    nextArrow:
      '<button type="button" className="slick-next"><i className="far fa-chevron-right"></i></button>',
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <section className="team-area2 fix p-relative pt-105 pb-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 p-relative">
              <div
                className="section-title center-align mb-50 text-center wow fadeInDown animated"
                data-animation="fadeInDown"
                data-delay=".4s"
              >
                <h5>Team </h5>
                <h2>Makes the Dream Work.</h2>
              </div>
            </div>
          </div>
          <Slider className="row team-active" {...satting}>
            {data.map((item) => (
              <div className="col-xl-4" key={item.id}>
                <div className="single-team mb-40">
                  <div className="team-thumb">
                    <div className="brd">
                      <img src={item.img} alt="img" />
                    </div>
                  </div>
                  <div className="team-info">
                    <h5>
                      <Link to="team-single.html">{item.name}</Link>
                    </h5>
                    <p><Link to="team-single.html">{item.Department}</Link></p>
                    <p><Link to="team-single.html">{item.Qualification}</Link></p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>
    </>
  );
}

export default Gallery2;
