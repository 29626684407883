import React from 'react'
import Bredcm from '../Menhome/breadcrumb'
import Othertreatments from '../ServisesecMen/Othertreatments'
function Main() {
  return (
    <>
        <Bredcm subtitle="Services Details" title="Home" subtitledown="Services Details" />
        <Othertreatments/>
    </>
  )
}

export default Main