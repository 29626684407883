import React, { useState } from 'react';
import Faqimg from '../../assets/img/bg/faq-img.png';
// import Faqimg from '../../assets/img/bg/faq-img.png';
// import Faqimg from '../../assets/img/bg/faq-img.png';
function Faqsection() {
    const [faqIndex, setFaqIndex] = useState(null);

    const faqs = [
        {
            Que: '1. Treatment Principle', 
            Answer:
                'Radiation therapy works by delivering high-energy radiation beams directly to the tumor or affected area. This radiation damages the DNA within cancer cells, preventing them from growing and dividing. Over time, the cancer cells die off, reducing the size of the tumor or eliminating it altogether.',
        },
        {
            Que: '2. Multidisciplinary Approach',
            Answer:
                'Like medical oncologists and surgical oncologists, radiation oncologists work closely with other specialists to provide comprehensive cancer care. They collaborate with medical physicists, radiation therapists, dosimetrists, and other healthcare professionals to plan and deliver precise radiation treatments.'
        },
        {
            Que: '3. Types of Radiation',
            Answer:
                '1) External Beam Radiation Therapy (EBRT); 2)  Internal Radiation Therapy (Brachytherapy); 3) Stereotactic Radiosurgery (SRS) and Stereotactic Body Radiation Therapy (SBRT); 4) Proton Therapy; 5) Radiation Therapy Combined with Other Treatments'
        },
        {
            Que: '4. Side Effects and Management',
            Answer:
                'Like any cancer treatment, radiation therapy can cause side effects. These may include fatigue, skin changes at the treatment site, hair loss (in the treated area), and other specific side effects depending on the location of the radiation. Radiation oncologists work closely with patients to manage these side effects and ensure they receive supportive care throughout their treatment.',
        },
    ];

    return (
        <>
            <section className="faq-area fix" style={{ backgroundColor: '#ffffff' }}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="section-title mb-50">
                                {/* <h5>FAQ</h5>
                                <h2>Frequently Asked Question</h2> */}
                            </div>
                            <div className="faq-wrap">
                                <div className="accordion" id="accordionExample">
                                    {faqs.map((faq, index) => (
                                        <div className="card" key={index}>
                                            <div className="card-header" id={`heading-${index}`}>
                                                <h2 className="mb-0">
                                                    <button
                                                        className={`faq-btn ${faqIndex === index ? '' : 'collapsed'
                                                            }`}
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        onClick={() => setFaqIndex(index)}
                                                        data-bs-target={`#collapse-${index}`}
                                                    >
                                                        {faq.Que}
                                                    </button>
                                                </h2>
                                            </div>
                                            {faqIndex === index && (
                                                <div
                                                    id={`collapse-${index}`}
                                                    className="collapse show"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <br/>
                                                    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Description</b></div>
                                                    <div className="card-body">{faq.Answer}</div>
                                                    {/* <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Purpose</b></div>
                                                    <div className="card-body">{faq.Purpose}</div> */}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-6">
                            <div className="faq-img text-right">
                                <img src={Faqimg} alt="img" className="img" />
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    );
}
export default Faqsection;
