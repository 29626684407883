import React from 'react'
import Iframe from 'react-iframe'


function Map() {
  return (
    <>
        <div className="map fix" style={{ background: "#f5f5f5" }}>
            <div className="container-flud">
                <div className="row">
                <div className="col-lg-12">
                    <Iframe src="https://maps.google.com/maps?q=Glean%Cancer%20Centre%&%Multispeciality%Hospital;t=;z=13;ie=UTF8;iwloc=&amp;output=embed" 
                           width={600}
                           height={450} 
                           style={{ border : "0" }}
                          loading="lazy">
                    </Iframe>
                </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Map