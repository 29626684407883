import React, { useState } from 'react';
import Faqimg from '../../assets/img/bg/faq-img.png';

function Faqsection() {
    const [faqIndex, setFaqIndex] = useState(null);

    const faqs = [
        {
            Que: 'What is cancer?', 
            Answer:
                'Cancer is a disease where abnormal cells grow uncontrollably, forming tumors that can invade nearby tissues and spread throughout the body. It can develop in any part of the body and has various causes, including genetic factors, environmental exposures, and lifestyle choices. Diagnosis involves imaging, biopsies, and tests to determine its stage. Treatment options like surgery, chemotherapy, radiation, targeted therapy, and immunotherapy aim to remove or destroy cancer cells. Prevention through healthy lifestyle choices and regular screenings is crucial. Research continues to advance understanding and improve treatment outcomes for the many types of cancer.',
        },
        {
            Que: 'What types of cancer do you treat?',
            Answer:
                'Blood Cancer, Breast Cancer, Head & Neck Cancer, Gall Bladder Cancer, Colon Cancer, Liver Cancer, Lung Cancer, Pancreas Cancer, Prostate Cancer',
        },
        {
            Que: 'How do I make an appointment?',
            Answer:
                'Either fill the form on the website by clicking here or send a message on this whatsapp number.',
        },
        {
            Que: 'What supportive care services do you offer?',
            Answer:
                'Oncology, General Medicine, General Surgery, Ortho Surgery, E.N.T Department, Respiratory Department, Cardiology Department, Anaesthesia, Haematology Pathology, Radiology, ICU, Trauma Centre, Day Care Service',
        },
    ];

    return (
        <>
            <section className="faq-area fix" style={{ backgroundColor: '#0e264f' }}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="section-title mb-50">
                                <h5>FAQ</h5>
                                <h2>Frequently Asked Question</h2>
                            </div>
                            <div className="faq-wrap">
                                <div className="accordion" id="accordionExample">
                                    {faqs.map((faq, index) => (
                                        <div className="card" key={index}>
                                            <div className="card-header" id={`heading-${index}`}>
                                                <h2 className="mb-0">
                                                    <button
                                                        className={`faq-btn ${faqIndex === index ? '' : 'collapsed'
                                                            }`}
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        onClick={() => setFaqIndex(index)}
                                                        data-bs-target={`#collapse-${index}`}
                                                    >
                                                        {faq.Que}
                                                    </button>
                                                </h2>
                                            </div>
                                            {faqIndex === index && (
                                                <div
                                                    id={`collapse-${index}`}
                                                    className="collapse show"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div className="card-body">{faq.Answer}</div>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="faq-img text-right">
                                <img src={Faqimg} alt="img" className="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Faqsection;
