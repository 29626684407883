import React from 'react'
import Bredcm from '../Menhome/breadcrumb'
import Oncosurgery from '../ServisesecMen/Oncosurgery'
function Main() {
  return (
    <>
        <Bredcm subtitle="Services Details" title="Home" subtitledown="Services Details" />
        <Oncosurgery/>
    </>
  )
}

export default Main