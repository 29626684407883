import React from 'react'
import Bredcm from '../Menhome/breadcrumb'
// import About from '../Menabout/About'
// import Appointment from '../Menhome/Appointment'
import Gallery1 from '../Menhome/Gallery1'
// import Gallery2 from '../Menhome/Gallery2'
// import Gallery3 from '../Menhome/Gallery3'

// import Faq from '../Menhome/Faqsection'
// import Blog from '../Menhome/Blog'


function Main() {
  return (
    <>
      <Bredcm subtitle="Photo Gallery" title="Home" subtitledown="Photo Gallery"/>
      <Gallery1/>
      {/* <Gallery2/> */}
      {/* <Gallery3/> */}
    </>
  )
}

export default Main