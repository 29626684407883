import React from 'react'
// import First from '../Menblog/First'
// import Second from '../Menblog/Second'
// import Sideone from '../Menblog/Sideone'
// import Three from '../Menblog/Three'
import Bredcm from '../Menhome/breadcrumb'
// import Pagination from '../Pagination/Main'
import Blog from '../Menhome/Blog'


function Main() {
  return (
    <>
      <Bredcm
          subtitle="Blog"
          title="Home"
          subtitledown="Blog" 
        />
      <Blog/>
    </>
  )
}

export default Main