import React from "react";
import { Link } from "react-router-dom";

function Team() {
  const data = [
    {
      id: 1,
      name: "Bableo Dablo",
      img: "assets/img/team/team01.png",
    },
    {
      id: 2,
      name: "Dawon Crisen",
      img: "assets/img/team/team02.png",
    },
    {
      id: 3,
      name: "David Suarez",
      img: "assets/img/team/team03.png",
    },
    {
      id: 4,
      name: "Gerard Peano",
      img: "assets/img/team/team04.png",
    },
    {
      id: 5,
      name: "Ostin Green",
      img: "assets/img/team/team05.png",
    },
    {
      id: 6,
      name: "Norman Colins",
      img: "assets/img/team/team06.png",
    },
    {
      id: 7,
      name: "Ostin Green",
      img: "assets/img/team/team07.png",
    },
    {
      id: 8,
      name: "Norman Colins",
      img: "assets/img/team/team08.png",
    },
  ];
  return (
    <>
      <section className="team-area fix p-relative pt-120 pb-90">
        <div className="container">
          <div className="row">
            {data.map((item) => (
              <div className="col-xl-3" key={item.id}>
                <div className="single-team mb-45">
                  <div className="team-thumb">
                    <div className="brd">
                      <img src={item.img} alt="img" />
                    </div>
                  </div>
                  <div className="team-info">
                    <h4>
                      <Link to="team-single.html">{item.name}</Link>
                    </h4>
                    <p>Worker</p>
                    <div className="team-social">
                      <ul>
                        <li>
                          <Link to="#">
                            <i className="fab fa-facebook-f" />
                          </Link>
                        </li>
                        <li>
                          {" "}
                          <Link to="#">
                            <i className="fab fa-twitter" />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="fab fa-instagram" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Team;
