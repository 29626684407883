import React from 'react'
import Breadcrumb from '../Menhome/breadcrumb'
import Third from '../Blogdetailsmen/Third'
// import Sideone from '../Menblog/Sideone'

function Main3() {
  return (
    <>
        <Breadcrumb
            subtitle="Blog Details"
            title="Home"
            subtitledown="Blog Details"
        />

        <section className="inner-blog b-details-p pt-120 pb-120">
             <div className="container"> 
                <div className="row">
                   <Third/>
                </div>
             </div>
        </section>
        
    </>
  )
}

export default Main3