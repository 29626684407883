import React from 'react';
import IconOne from '../../assets/img/icon/pr-icon1.png';
import IconTwo from '../../assets/img/icon/pr-icon2.png';
import IconThree from '../../assets/img/icon/pr-icon3.png';
import { Link } from 'react-router-dom';

const plans = [
  {
    icon: IconOne,
    name: 'Basic Plan',
    price: '$49',
    features: [
      'Aliquam quis justo at lorem',
      'Fusce sodales, urna et tempus',
      'Vestibulum blandit lorem quis',
      'Orci varius natoque penatibus',
      'Aliquam a nisl congue, auctor',
    ],
  },
  {
    icon: IconTwo,
    name: 'Standard Plan',
    price: '$99',
    features: [
      'Aliquam quis justo at lorem',
      'Fusce sodales, urna et tempus',
      'Vestibulum blandit lorem quis',
      'Orci varius natoque penatibus',
      'Aliquam a nisl congue, auctor',
    ],
  },
  {
    icon: IconThree,
    name: 'Unlimited Plan',
    price: '$149',
    features: [
      'Aliquam quis justo at lorem',
      'Fusce sodales, urna et tempus',
      'Vestibulum blandit lorem quis',
      'Orci varius natoque penatibus',
      'Aliquam a nisl congue, auctor',
    ],
  },
];

function Pricing() {
  return (
    <section id="pricing" className="pricing-area pt-120 pb-60 fix p-relative">
      <div className="container">
        <div className="row">
          {plans.map((plan, index) => (
            <div className="col-lg-4 col-md-6" key={index}>
              <div className="pricing-box pricing-box2 text-center mb-60">
                <div className="pricing-head">
                  <div className="icon">
                    <img src={plan.icon} alt="img" />
                  </div>
                  <h3>{plan.name}</h3>
                  <div className="price-count">
                    <h2>
                      {plan.price} <span>/ Monthly</span>
                    </h2>
                  </div>
                  <hr />
                </div>
                <div className="pricing-body mt-20 mb-30 text-left">
                  <ul>
                    {plan.features.map((feature, index) => (
                      <li key={index}>{feature}</li>
                    ))}
                  </ul>
                </div>
                <div className="pricing-btn">
                  <Link to="contact.html" className="btn ss-btn">
                    Choose Plan
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Pricing;
