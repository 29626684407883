import React from "react";
import { Link } from "react-router-dom";

function Shop() {
  const data = [
    {
      id: 1,
      img: "assets/img/shop/oet1.png",
      name: "General Medicine",
      subname: "CHAIR",
    },
    {
      id: 2,
      img: "assets/img/shop/oet2.png",
      name: "General Surgery",
      subname: "CLOTHS",
    },
    {
      id: 3,
      img: "assets/img/shop/oet3.png",
      name: "Ortho Surgery",
      subname: "LIGHT",
    },
    {
      id: 4,
      img: "assets/img/shop/oet4.png",
      name: "E.N.T Department",
      subname: "HEADPHONE",
    },
    {
      id: 5,
      img: "assets/img/shop/oet5.png",
      name: "Respiratory Department",
      subname: "TABLE",
    },
    {
      id: 6,
      img: "assets/img/shop/oet6.png",
      name: "Cardiology Department",
      subname: "CLOTHS",
    },
    {
      id: 7,
      img: "assets/img/shop/oet7.png",
      name: "Anaesthesia",
      subname: "CLOTHS",
    },
    {
      id: 8,
      img: "assets/img/shop/oet8.png",
      name: "Haematology",
      subname: "CLOTHS",
    },
    {
      id: 9,
      img: "assets/img/shop/oet9.png",
      name: "Pathology",
      subname: "TABLE",
    },
    {
      id: 10,
      img: "assets/img/shop/oet10.png",
      name: "Radiology",
      subname: "TABLE",
    },
    {
      id: 11,
      img: "assets/img/shop/oet11.png",
      name: "Intensive Care Unit",
      subname: "TABLE",
    },
    {
      id: 12,
      img: "assets/img/shop/oet12.png",
      name: "Trauma Centre",
      subname: "TABLE",
    },
  ];
  return (
    <>
      <section className="shop-area pt-0 pb-120 p-relative"
        data-animation="fadeInUp animated"
        data-delay=".2s">
        <div className="container">
          <div className="row align-items-center">
            {data.map((item) => (
              <div className="col-lg-4 col-md-6" key={item.id}>
                <div className="product mb-40">
                  <div className="product__img">
                    <Link to="/Shopdetails">
                      <img src={item.img} alt="" />
                    </Link>
                  </div>
                  <div className="product__content text-center pt-30">
                    <h4 className="pro-title">
                      <Link to="/Shopdetails">{item.name}</Link>
                    </h4>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Shop;
